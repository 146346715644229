import { useImage } from 'react-image'

export default function View ({ src, className = '', ...rest }) {
  const data = useImage({
    srcList: src,
    useSuspense: false,
  })

  return <img {...rest} src={data.src} className={`${className} transition-opacity duration-300`} style={{ opacity: data.isLoading ? 0 : 1 }} />
}
