import { Spin, Button } from 'components'
import { SearchBar, Empty } from 'antd-mobile'

import { saveAs } from 'file-saver'
import { useReactive, useInfiniteScroll } from 'ahooks'

async function fetchData (data, name, external) {
  const result = await external.api.get('/productFile/selectPage', {
    params: {
      name,
      size: 10,
      page: Math.floor(R.pathOr(0, [ 'list', 'length' ], data) / 10) + 1,
    },
  })

  return {
    list: result.data.list,
    total: result.data.totalRow,
  }
}

export default function View ({ store }) {
  const data = useReactive({ name: new URL(location.href).searchParams.get('query') })
  const res = useInfiniteScroll(e => fetchData(e, data.name, store.external), { reloadDeps: [ data.name ] })

  if(store.responsive.isDesktop) {
    return <div className='p-8 px-32 space-y-4 widescreen:px-64' style={{ minHeight: '60vh' }}>
      <div className='flex justify-end'>
        <SearchBar
          className='w-80'
          placeholder='输入产品名搜索'
          defaultValue={data.name}
          onClear={() => Object.assign(data, { name: '' })}
          onSearch={value => Object.assign(data, { name: value })}
        />
      </div>

      <table className='w-full border-collapse'>
        <thead>
          <tr className='text-center bg-blue-900 text-white text-lg'>
            <th className='p-4'>产品名称</th>
            <th className='p-4'>资料名称</th>
            <th className='p-4'>上传时间</th>
            <th className='p-4'>操作</th>
          </tr>
        </thead>

        <tbody className='divide-y divide-dashed'>
          {res.loading ? <tr><td className='animate-pulse py-64 text-xl text-center bg-white' colSpan={4}>Loading data...</td></tr> : R.propOr(0, 'length', res.data?.list) == 0 ? <tr><td className='py-64 text-xl text-center bg-white' colSpan={4}>暂无相关资料.</td></tr> : res.data.list.map(elm => {
            console.log(elm)
            return <tr key={elm.id} className='bg-white text-center text-base'>
              <td className='p-4 py-6'>
                <h3 className='text-gray-800'>{elm.productName}</h3>
              </td>

              <td className='p-4 py-6'>
                <a className='text-gray-800 underline' href={elm.url}>{elm.fileName}</a>
              </td>

              <td className='p-4 py-6'>
                <time className='text-gray-500'>{moment(elm.updateTime).format('YYYY-MM-DD')}</time>
              </td>

              <td className='p-4 py-6'>
                <Button className='text-sm' onClick={() => saveAs(elm.url, elm.fileName)}>下载文件</Button>
              </td>
            </tr>
          })}
        </tbody>

        {res.data?.list.length == res.data?.total ? null : <tfoot>
          <tr>
            <td colSpan={4} className='p-4 py-6'>
              <div className='flex justify-center'>
                <Button loading={res.loadingMore} onClick={() => res.loadMore()}>加载更多</Button>
              </div>
            </td>
          </tr>
        </tfoot>}
      </table>
    </div>
  }

  return <div className='p-4 space-y-4' style={{ minHeight: '60vh' }}>
    <div>
      <SearchBar
        placeholder='输入产品名搜索'
        onClear={() => Object.assign(data, { name: null })}
        onSearch={value => Object.assign(data, { name: value })}
      />
    </div>

    <Spin spinning={res.loading}>
      <ul className='space-y-4'>
        {res.data?.list.map(elm => {
          return <li key={elm.id} className='p-4 bg-white rounded flex items-center justify-between'>
            <div className='flex-auto space-y-2'>
              <p className='text-gray-800 font-bold'>{elm.productName}</p>
              <p className='text-gray-800 underline'>{elm.fileName}</p>
              <p className='text-gray-500'>{moment(elm.updateTime).format('YYYY-MM-DD')}</p>
            </div>

            <div className='flex-none'>
              <button
                onClick={() => saveAs(elm.url, elm.fileName)}
                className='py-1 px-4 bg-blue-800 text-white rounded-full outline-none ring-blue-800 ring-opacity-50 focus:ring disabled:opacity-50'
              >
                下载文件
              </button>
            </div>
          </li>
        })}
      </ul>

      <section className={res.data?.list.length > 1 ? 'hidden' : ''}>
        <Empty style={{ height: 260, background: '#fff' }} description='暂无信息' />
      </section>

      <section className={res.data?.list.length < res.data?.total ? 'p-4 flex justify-center' : 'hidden'}>
        <button
          onClick={() => res.loadMore()}
          disabled={res.loadingMore}
          className='py-1 px-4 bg-blue-800 text-white rounded-full outline-none ring-blue-800 ring-opacity-50 focus:ring disabled:opacity-50'
        >
          {res.loadingMore ? '加载中...' : '加载更多'}
        </button>
      </section>
    </Spin>
  </div>
}
