import { useRequest } from 'ahooks'

export default function View ({ store, route, external }) {
  const res = useRequest(async () => {
    const result = await external.api.get('/product/select', {
      params: {
        name: route.params.id,
      }
    })

    return result.data.list
  }, {
    loadingDelay: 600,
    refreshDeps: [ route.params.id ],
  })

  if(res.loading) {
    return <main className='m-4 py-64 bg-white flex items-center justify-center'>
      <div className='animate-pulse text-xl'>Loading data...</div>
    </main>
  }

  if(R.propOr(0, 'length', res.data) == 0) {
    return <main className='m-4 bg-white flex items-center justify-center'>
      <div className='flex items-center justify-center text-gray-600 text-2xl py-72'>
        未查询到 "{route.params.id}" 相关的产品.
      </div>
    </main>
  }

  return <main className='m-4 p-4 px-40'>
    <ul className='grid grid-cols-4 gap-8'>
      {res.data?.map(elm => {
        return <li
          key={elm.id}
          onClick={() => store.navigate.go(`/product/detail/${elm.id}`)}
          className='p-6 px-8 bg-white cursor-pointer'
        >
          <div>
            <img className='w-full h-72 object-contain' src={elm.url} />
          </div>

          <div className='divide-y leading-6'>
            <p className='py-4 text-xl text-gray-800'>{elm.title}</p>
            <p className='py-4 text-base text-gray-600'>{elm.desc}</p>
          </div>
        </li>
      })}
    </ul>
  </main>
}
