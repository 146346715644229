import { useHistory, useLocation } from 'react-router'

export default () => {
  const history = useHistory()
  const location = useLocation()

  return {
    back: () => history.goBack(),
    go: (url: string) => history.push(url),
    absolute: (url: string) => history.push(url),
    dispatch: (url: string) => history.push(url),
    relative: (url: string) => history.push(`${location.pathname}/${url}`.replace(/\/+/g, '\/')),
  }
}
