import http from '@sar/http'
import framework from '@sar/framework'

import responsive from 'stores/responsive'
import external from 'stores/external'
import config from 'stores/config'
import navigate from 'stores/navigate'
import scroller from 'stores/scroller'

import authorize from 'middlewares/authorize'
import notification from 'middlewares/notification'

import Home from 'views/home'
import About from 'views/about'
import Index from 'views/index'
import Server from 'views/server'
import Program from 'views/program'

import Cache from 'plugins/cache'
import Layout from 'plugins/layout'

const APP = (
  framework
    .create()
    .set((app: framework) => app.usePlugin(Layout))
    .set((app: framework) => app.usePlugin(Cache))
    .set((app: framework) => app.useStore('responsive', responsive))
    .set((app: framework) => app.useStore('external', external))
    .set((app: framework) => app.useStore('config', config))
    .set((app: framework) => app.useStore('navigate', navigate))
    .set((app: framework) => app.useStore('scroller', scroller))
    .set((app: framework) => app.useRouter('/', Index))
    .set((app: framework) => app.useRoute('/home', Home))
    .set((app: framework) => app.useRoute('/about/:id', About))
    .set((app: framework) => app.useRoute('/server/:id', Server))
    .set((app: framework) => app.useRoute('/program/:id', Program))
    .set((app: framework) => app.useRedirect('/program', '/program/1'))
    .set((app: framework) => app.useRedirect('/consult', '/consult/1'))
    .set((app: framework) => app.useRedirect('/about', '/about/profile'))
    .set((app: framework) => app.useRedirect('/server', '/server/home'))
    .set((app: framework) => app.useRedirect('/', '/home'))
    .set((app: framework) => app.useRedirect('/*', '/home'))
)

http.useProxy('request', authorize)
http.useProxy('response', notification)

http.setting('base', APP_ENV.api)
http.setting('adapter', APP_ENV.adapter)

ReactDOM.render(<APP.DOM store={APP} />, document.getElementById('root'))
