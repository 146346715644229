import { useStore } from '@sar/framework/hooks'
import { useRequest } from 'ahooks'

export default (Target: React.FC<any>) => function Cache({ match }) {
  const store = useStore()
  const external = useStore('external')

  const res = useRequest(() => Promise.all([
    external.api.get('/config/selectAddress').then(R.prop('data')),
    external.api.get('/news/selectHome').then(R.prop('data')).then(R.prop('list')),
    external.api.get('/banner/selectAll').then(R.prop('data')).then(R.prop('list')),
    external.api.get('/productType/selectAll').then(R.prop('data')).then(R.prop('list')),
  ]), {
    staleTime: -1,
    cacheKey: 'website:db',
  })

  const data = {
    address: R.nth(0, R.defaultTo([], res.data)),
    news: R.nth(1, R.defaultTo([], res.data)),
    banner: R.nth(2, R.defaultTo([], res.data)),
    product: R.nth(3, R.defaultTo([], res.data)),
  }

  if(res.loading) {
    return <div className='bg-gray-900 h-screen flex items-center justify-center'>
      <div className='fa fa-spinner animate-spin text-2xl text-white' />
    </div>
  }

  return <Target res={data} store={store} external={external} route={match} useRequest={useRequest} />
}
