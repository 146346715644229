import Home from './home'
import List from './list'
import Detail from './detail'
import Search from './search'

export default function View (prefix, app) {
  return app
    .useRoute(`${prefix}/detail/:id`, Detail)
    .useRoute(`${prefix}/search/:id`, Search)
    .useRoute(`${prefix}/list/:id`, List)
    .useRoute(`${prefix}/`, Home)
}
