import { useInfiniteScroll } from 'ahooks'

import { Empty } from 'antd-mobile'
import { Button, Image, Spin } from 'components'

async function fetchData(external, params) {
  const result = await external.api.get('/news/selectPage', {
    params,
  })

  return {
    list: result.data.list,
    last: result.data.lastPage,
    total: result.data.totalRow,
  }
}

export default function View ({ store, route, external }) {
  const res = useInfiniteScroll(e => fetchData(external, {
    size: 6,
    type: route.params.id,
    page: Math.floor(R.pathOr(0, [ 'list', 'length' ], e) / 6) + 1
  }), {
    reloadDeps: [ route.params.id ],
  })

  React.useEffect(() => {

    scrollTo(0, 0)

  }, [])

  if(store.responsive.isDesktop) {
    return <main>
      <Image className='w-full object-cover' src={store.config.loadImg('/resources/banner-2.jpg')} />

      <ul className='bg-white flex flex-wrap items-center justify-center text-lg'>
        {store.config.getConstantValues('Type').map(elm => {
          return <li
            key={elm.id}
            onClick={() => store.navigate.absolute(`/consult/${elm.value}`)}
            className={`px-8 py-4 cursor-pointer hover:text-blue-800 ${route.params.id == elm.value ? 'text-blue-800' : ''}`}
          >
            {elm.description}
          </li>
        })}
      </ul>

      {res.loading ? <div className='animate-pulse py-64 text-xl text-center'>Loading data...</div> : R.propOr(0, 'length', res.data?.list) == 0 ? <div className='py-64 text-xl text-center'>暂无相关数据.</div> : <div>
        <ul className='p-6 px-32 grid grid-cols-3 gap-8 widescreen:px-64'>
          {res.data?.list.map(elm => {
            return <li key={elm.key} className='p-2 space-y-8 cursor-pointer' onClick={e => store.navigate.go(`/consult/detail/${elm.id}`)}>
              <div className='space-y-4'>
                <Image className='h-64 w-full object-fill rounded-lg widescreen:80' src={elm.image} />
              </div>

              <div className='space-y-4'>
                <div className='text-lg text-gray-800'>{elm.title}</div>
                <div className='text-base text-gray-600'>{moment(elm.releaseTime).format('YYYY-MM-DD')}</div>
              </div>
            </li>
          })}
        </ul>

        <div className={`pb-4 flex justify-center ${R.compose(R.not, R.defaultTo(false))(res.data?.last) ? '' : 'hidden'}`.trim()}>
          <Button className='text-base' loading={res.loadingMore} onClick={() => res.loadMore()}>
            加载更多
          </Button>
        </div>
      </div>}
    </main>
  }

  return <main>
    <ul className='p-4 bg-white flex flex-wrap items-center divide-x'>
      {store.config.getConstantValues('Type').map(elm => {
        const classname = external.declare('px-2 my-2', {
          'font-bold': route.params.id == elm.value,
          'text-blue-800': route.params.id == elm.value,
        })

        return <li key={elm.value} className={classname} onClick={e => store.navigate.go(`/consult/${elm.value}`)}>
          {elm.description}
        </li>
      })}
    </ul>

    <Image className='w-full object-cover' src={store.config.loadImg('/resources/banner-2.jpg')} />

    <Spin className='p-4' spinning={res.loading} style={{ minHeight: '60vh' }}>
      <ul className='space-y-4'>
        {R.pathOr(0, [ 'list', 'length', ], res.data) == 0 ? <Empty style={{ height: 260, background: '#fff' }} description='暂无信息' /> : res.data?.list.map(elm => {
          return <li key={elm.key} className='p-2 space-y-4' onClick={e => store.navigate.go(`/consult/detail/${elm.id}`)}>
            <Image className='w-full object-cover rounded-lg' src={elm.image} />
            <h3 className='text-gray-800 font-bold'>{elm.title}</h3>
            <time className='text-gray-600'>{moment(elm.releaseTime).format('YYYY-MM-DD')}</time>
          </li>
        })}
      </ul>

      {R.defaultTo(false, res.data?.last) == false && <section className='p-4 flex justify-center'>
        <button className='py-1 px-4 bg-blue-800 text-white rounded-full outline-none ring-blue-800 ring-opacity-50 focus:ring disabled:opacity-50' disabled={res.loadingMore} onClick={() => res.loadMore()}>
          {res.loadingMore ? '加载中...' : '加载更多'}
        </button>
      </section>}
    </Spin>
  </main>
}
