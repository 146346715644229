export default function View ({ store }) {
  if(store.responsive.isDesktop) {
    return <div>
      <h1 className='pt-16 text-2xl text-center font-bold'>
        保修服务及期限承诺
      </h1>

      <section className='p-8 px-64'>
        <table className='w-full border-collapse text-base'>
          <thead>
            <tr className='bg-blue-900 text-white'>
              <th className='p-4 text-center border border-gray-400'>部件分类</th>
              <th className='p-4 text-center border border-gray-400'>部件名称</th>
              <th className='p-4 text-center border border-gray-400'>保修期限</th>
              <th className='p-4 text-center border border-gray-400'>服务方式</th>
            </tr>
          </thead>

          <tbody>
            <tr className='odd:bg-white bg-blue-100'>
              <td className='p-4 text-center border border-gray-400 w-48'>关键部件</td>
              <td className='p-4 text-center border border-gray-400'>主板、处理器（CPU）、内存、硬盘、电源、模组、磁盘阵列RAID控制器（含控制器Cache）、光纤通道卡、以太网卡、SCSI卡、GPU卡等</td>
              <td className='p-4 text-center border border-gray-400'>自购买之日起3年</td>
              <td className='p-4 text-center border border-gray-400 w-64'>7×24小时热线服务<br/>5×12小时远程技术支持<br/>5×8小时用户现场服务<br/>用户送（寄）修服务<br/></td>
            </tr>

            <tr className='odd:bg-white bg-blue-100'>
              <td className='p-4 text-center border border-gray-400'>辅助部件</td>
              <td className='p-4 text-center border border-gray-400'>板卡（主板除外）、CPU风扇、电源开关、LED指示灯、机箱灯箱、RACK导轨、机箱风扇、LCD显示屏、RAID卡电池等</td>
              <td className='p-4 text-center border border-gray-400'>自购买之日起3年</td>
              <td className='p-4 text-center border border-gray-400'>7×24小时热线服务<br/>5×12小时远程技术支持<br/>5×8小时用户现场服务<br/>用户送（寄）修服务</td>
            </tr>

            <tr className='odd:bg-white bg-blue-100'>
              <td className='p-4 text-center border border-gray-400'>外设部件</td>
              <td className='p-4 text-center border border-gray-400'>软驱、光驱、IDE硬盘、键盘、鼠标、UPS电池等</td>
              <td className='p-4 text-center border border-gray-400'>自购买之日起1年</td>
              <td className='p-4 text-center border border-gray-400'>发货更换服务</td>
            </tr>

            <tr className='odd:bg-white bg-blue-100'>
              <td className='p-4 text-center border border-gray-400' colSpan={2}>保修期内增配部件</td>
              <td className='p-4 text-center border border-gray-400'>自购买之日起，以部件厂商保修期为准</td>
              <td className='p-4 text-center border border-gray-400'>随整机同等年限服务标准</td>
            </tr>

            <tr className='odd:bg-white bg-blue-100'>
              <td className='p-4 text-center border border-gray-400' colSpan={2}>保修期外增配部件</td>
              <td className='p-4 text-center border border-gray-400'>自购买之日起，以部件厂商保修期为准</td>
              <td className='p-4 text-center border border-gray-400'>7×24小时热线服务<br/>发货更换服务</td>
            </tr>

            <tr className='odd:bg-white bg-blue-100'>
              <td className='p-4 text-center border border-gray-400' colSpan={2}>独立采购部件</td>
              <td className='p-4 text-center border border-gray-400'>自购买之日起，以部件厂商保修期为准</td>
              <td className='p-4 text-center border border-gray-400'>7×24小时热线服务<br/>发货更换服务</td>
            </tr>
          </tbody>
        </table>
      </section>

      <h1 className='p-4 pt-8 text-xl text-center font-bold'>
        服务方式定义
      </h1>

      <section className='p-4 px-64 text-base space-y-1 leading-loose'>
        <p><strong>1.保修期限说明：</strong></p>
        <p>产品保修的起始日期为送货单上日期为准，如送货单日期无法查实，以昊源诺信记录的服务器出厂日期为准。</p>
        <p>产品保修期届满后，用户还可以继续享受昊源诺信免费提供的5x8小时电话及电子邮件技术支持服务，但是不再享受保修服务承诺。如需提供保修服务，本着双方的友好合作关系，昊源诺信额外收取可控成本的技术服务及维修费用。</p>
        <p>经昊源诺信维修后的服务器产品，保修期内继续享有本售后服务承诺。如自修复之日起距免费保修期结束不足三个月，昊源诺信承诺将免费保修服务期限延长至自修复之日起三个月止。</p>
        <p>保修过的部件，其保修期无须重新计算，仍以交货之日为保修起算日期。</p>
      </section>

      <section className='p-4 px-64 text-base space-y-1 leading-loose'>
        <p><strong>2.保修响应时间</strong></p>
        <p>昊源诺信所提供服务器产品实行全国范围联保，为客户提供7×24小时热线服务，用户可联系服务热线400-6997-916或专职服务联系人电话进行报修。接到报修后，技术服务人员会在首次进行故障研判及技术沟通。在保修期限内，昊源诺信售后服务工程师将在接到用户报修请求后2个小时内与用户电话联系做出响应，确定维修事宜，并在4小时内提出解决方案。如果判定需要现场服务，会安排就近的售后服务人员进行现场服务。昊源诺信可对用户提供全国范围内上门服务（港澳台地区除外），主要地区上门服务响应时间为48小时内，上门服务安排具体事宜双方协商处理。</p>
      </section>

      <section className='p-4 px-64'>
        <table className='w-full border-collapse text-base'>
          <thead>
            <tr className='bg-blue-900 text-white'>
              <th className='p-4 text-center border border-gray-400'>保修SLA内容</th>
              <th className='p-4 text-center border border-gray-400'>响应时间</th>
              <th className='p-4 text-center border border-gray-400'>备注</th>
            </tr>
          </thead>

          <tbody>
            <tr className='odd:bg-white bg-blue-100'>
              <td className='p-4 text-center border border-gray-400'>免费上门服务期限</td>
              <td className='p-4 text-center border border-gray-400'>三年保修期内免费上门服务</td>
              <td className='p-4 text-center border border-gray-400'></td>
            </tr>

            <tr className='odd:bg-white bg-blue-100'>
              <td className='p-4 text-center border border-gray-400'>产品故障服务响应时限</td>
              <td className='p-4 text-center border border-gray-400'>2小时内</td>
              <td className='p-4 text-center border border-gray-400'></td>
            </tr>

            <tr className='odd:bg-white bg-blue-100'>
              <td className='p-4 text-center border border-gray-400'>全国上门服务时限（主要地区）</td>
              <td className='p-4 text-center border border-gray-400'>48小时内</td>
              <td className='p-4 text-center border border-gray-400'>华东、华北、华中、华南全区，西南、西北、东北主要城市地区</td>
            </tr>

            <tr className='odd:bg-white bg-blue-100'>
              <td className='p-4 text-center border border-gray-400'>全国上门服务时限（偏远地区）</td>
              <td className='p-4 text-center border border-gray-400'>72小时内</td>
              <td className='p-4 text-center border border-gray-400'>西南、西北、东北等偏远地区</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className='p-4 px-64 text-base space-y-1 leading-loose'>
        <p className='first:font-bold'>3.保修服务范围及履行条件</p>
        <p className='first:font-bold'>1) 本标准保修服务承诺仅限于昊源诺信所提供服务器整机、服务器部件及相关外设部件在保修期内正常使用所出现的故障。</p>
        <p className='first:font-bold'>2) 对于操作系统、用户应用软件在内的各种软件层面问题，昊源诺信会基于双方友好合作基础，尽可能进行技术支持协助用户解决故障及问题，但不承担由此导致的用户业务不可用责任。</p>
        <p className='first:font-bold'>3) 对于超出承诺范围的服务，或保修期外的服务，昊源诺信会基于双方友好合作基础，提供成本可控的有偿服务，具体可咨询昊源诺信技术服务中心。</p>
        <p className='first:font-bold'>4) 以下提及的情形，不在保修服务范围内：</p>
      </section>

      <section className='ml-4 px-64 pb-4 text-sm text-gray-600 space-y-1 leading-loose'>
        <p> • 因采用非昊源诺信所提供设备及备件，并由其本身质量问题或不兼容性导致的故障或损坏</p>
        <p> • 说明文件中未提及的、未被认可的不规范操作（如带电插拔数据线等）造成的故障或损坏</p>
        <p> • 在非产品所规定工作环境下使用造成的故障及损坏（包括但不限于：温度过高、过低，过于潮湿或干燥，海拔过高，电压或者电流不稳定、零地电压过大，静电干扰，非正常的物理压力及振动，环境灰尘过多等）</p>
        <p> • 因维护不当导致的计算病毒感染、恶意软件、非法入侵等带来的故障和损坏</p>
        <p> • 因非昊源诺信授权的机构、人员实施的安装、维修、更改或拆卸所造成的故障和损坏</p>
        <p> • 因直接的或间接的不可避免的原因或不可抗拒力（如自然灾害、战争等）造成的故障或损坏</p>
        <p> • 因事故、滥用（包括长期超负荷运行）、误用造成的故障及损坏</p>
      </section>

      <section className='p-4 px-64 text-base space-y-1 leading-loose'>
        <p className='first:font-bold'>4.保修免责条款</p>
        <p className='first:font-bold'>用户应对其数据的安全性自行负责。在维修前自行对系统数据、程序等进行备份，昊源诺信不对维修过程中数据、计算机程序的丢失或者损坏承担责任。</p>
        <p className='first:font-bold'>现场服务可能要求昊源诺信售后工程师接触非昊源诺信交付或者出品的软件或硬件。若由昊源诺信对软件或硬件进行操作，可能导致被操作的厂家的产品保修失效。用户应该保证昊源诺信的上述服务行为不会影响这种保修，或者能够接受由此带来的失效结果。昊源诺信不对第三方保修失效所造成的影响承担任何责任。</p>
        <p className='first:font-bold'>在服务器产品良好运行时，昊源诺信对用户的责任仅限于按照相应的条款进行产品的维修和更换，除此之外，无其他担保。除非国家法律有其他明确规定，昊源诺信将遵照法律执行。</p>
      </section>

      <section className='p-4 px-64 text-base space-y-1 leading-loose pb-8'>
        <p className='first:font-bold'>5.协议解释与说明</p>
        <p className='first:font-bold'>本售后服务协议适用于用户在中华人民共和国境内（港、澳、台地区除外）销售、购买并使用的由昊源诺信提供的服务器产品。</p>
      </section>
    </div>
  }

  return <div>
    <h1 className='pt-4 text-lg text-center font-bold'>
      保修服务及期限承诺
    </h1>

    <ul>
      <li className='odd:bg-gray-100 bg-white p-4 space-y-4'>
        <section>
          <p className='p-1 font-bold text-base'>关键部件</p>
          <p className='p-1'>主板、处理器（CPU）、内存、硬盘、电源、模组、磁盘阵列RAID控制器（含控制器Cache）、光纤通道卡、以太网卡、SCSI卡、GPU卡等</p>
        </section>

        <section>
          <p className='p-1 font-bold'>保修期限</p>
          <p className='p-1'>自购买之日起3年</p>
        </section>

        <section>
          <p className='p-1 font-bold'>服务方式</p>
          <p className='p-1'>7×24小时热线服务<br/>5×12小时远程技术支持<br/>5×8小时用户现场服务<br/>用户送（寄）修服务</p>
        </section>
      </li>

      <li className='odd:bg-gray-100 bg-white p-4 space-y-4'>
        <section>
          <p className='p-1 font-bold text-base'>辅助部件</p>
          <p className='p-1'>板卡（主板除外）、CPU风扇、电源开关、LED指示灯、机箱灯箱、RACK导轨、机箱风扇、LCD显示屏、RAID卡电池等</p>
        </section>

        <section>
          <p className='p-1 font-bold'>保修期限</p>
          <p className='p-1'>自购买之日起3年</p>
        </section>

        <section>
          <p className='p-1 font-bold'>服务方式</p>
          <p className='p-1'>7×24小时热线服务<br/>5×12小时远程技术支持<br/>5×8小时用户现场服务<br/>用户送（寄）修服务</p>
        </section>
      </li>

      <li className='odd:bg-gray-100 bg-white p-4 space-y-4'>
        <section>
          <p className='p-1 font-bold text-base'>外设部件</p>
          <p className='p-1'>软驱、光驱、IDE硬盘、键盘、鼠标、UPS电池等</p>
        </section>

        <section>
          <p className='p-1 font-bold'>保修期限</p>
          <p className='p-1'>自购买之日起1年</p>
        </section>

        <section>
          <p className='p-1 font-bold'>服务方式</p>
          <p className='p-1'>发货更换服务</p>
        </section>
      </li>

      <li className='odd:bg-gray-100 bg-white p-4 space-y-4'>
        <section>
          <p className='p-1 font-bold text-base'>保修期内增配部件</p>
        </section>

        <section>
          <p className='p-1 font-bold'>保修期限</p>
          <p className='p-1'>自购买之日起，以部件厂商保修期为准</p>
        </section>

        <section>
          <p className='p-1 font-bold'>服务方式</p>
          <p className='p-1'>随整机同等年限服务标准</p>
        </section>
      </li>

      <li className='odd:bg-gray-100 bg-white p-4 space-y-4'>
        <section>
          <p className='p-1 font-bold text-base'>保修期外增配部件</p>
        </section>

        <section>
          <p className='p-1 font-bold'>保修期限</p>
          <p className='p-1'>自购买之日起，以部件厂商保修期为准</p>
        </section>

        <section>
          <p className='p-1 font-bold'>服务方式</p>
          <p className='p-1'>7×24小时热线服务<br/>发货更换服务</p>
        </section>
      </li>

      <li className='odd:bg-gray-100 bg-white p-4 space-y-4'>
        <section>
          <p className='p-1 font-bold text-base'>独立采购部件</p>
        </section>

        <section>
          <p className='p-1 font-bold'>保修期限</p>
          <p className='p-1'>自购买之日起，以部件厂商保修期为准</p>
        </section>

        <section>
          <p className='p-1 font-bold'>服务方式</p>
          <p className='p-1'>7×24小时热线服务<br/>发货更换服务</p>
        </section>
      </li>
    </ul>

    <h1 className='p-4 pt-8 text-lg text-center font-bold'>
      服务方式定义
    </h1>

    <section className='p-4'>
      <p className='p-1 font-bold'>1.保修期限说明：</p>
      <p className='p-1 leading-6'>产品保修的起始日期为送货单上日期为准，如送货单日期无法查实，以昊源诺信记录的服务器出厂日期为准。</p>
      <p className='p-1 leading-6'>产品保修期届满后，用户还可以继续享受昊源诺信免费提供的5x8小时电话及电子邮件技术支持服务，但是不再享受保修服务承诺。如需提供保修服务，本着双方的友好合作关系，昊源诺信额外收取可控成本的技术服务及维修费用。</p>
      <p className='p-1 leading-6'>经昊源诺信维修后的服务器产品，保修期内继续享有本售后服务承诺。如自修复之日起距免费保修期结束不足三个月，昊源诺信承诺将免费保修服务期限延长至自修复之日起三个月止。</p>
      <p className='p-1 leading-6'>保修过的部件，其保修期无须重新计算，仍以交货之日为保修起算日期。</p>
    </section>

    <section className='p-4'>
      <p className='p-1 font-bold'>2.保修响应时间</p>
      <p className='p-1 leading-6'>昊源诺信所提供服务器产品实行全国范围联保，为客户提供7×24小时热线服务，用户可联系服务热线400-6997-916或专职服务联系人电话进行报修。接到报修后，技术服务人员会在首次进行故障研判及技术沟通。在保修期限内，昊源诺信售后服务工程师将在接到用户报修请求后2个小时内与用户电话联系做出响应，确定维修事宜，并在4小时内提出解决方案。如果判定需要现场服务，会安排就近的售后服务人员进行现场服务。昊源诺信可对用户提供全国范围内上门服务（港澳台地区除外），主要地区上门服务响应时间为48小时内，上门服务安排具体事宜双方协商处理。</p>
    </section>

    <section className='px-4 pb-4 space-y-2'>
      <article>
        <p className='p-1 font-bold'>免费上门服务期限</p>
        <p className='p-1'>三年保修期内免费上门服务</p>
      </article>

      <article>
        <p className='p-1 font-bold'>产品故障服务响应时限</p>
        <p className='p-1'>2小时内</p>
      </article>

      <article>
        <p className='p-1 font-bold'>全国上门服务时限（*主要地区）</p>
        <p className='p-1'>48小时内</p>
      </article>

      <article>
        <p className='p-1 font-bold'>全国上门服务时限（*偏远地区）</p>
        <p className='p-1'>72小时内</p>
      </article>

      <article>
        <p className='p-1 text-gray-500 font-bold'>*主要地区</p>
        <p className='p-1 text-xs text-gray-500'>华东、华北、华中、华南全区，西南、西北、东北主要城市地区</p>
      </article>

      <article>
        <p className='p-1 text-gray-500 font-bold'>*偏远地区</p>
        <p className='p-1 text-xs text-gray-500'>西南、西北、东北等偏远地区</p>
      </article>
    </section>

    <section className='p-4'>
      <p className='p-1 font-bold'>3.保修服务范围及履行条件</p>
      <p className='p-1 leading-6'>1) 本标准保修服务承诺仅限于昊源诺信所提供服务器整机、服务器部件及相关外设部件在保修期内正常使用所出现的故障。</p>
      <p className='p-1 leading-6'>2) 对于操作系统、用户应用软件在内的各种软件层面问题，昊源诺信会基于双方友好合作基础，尽可能进行技术支持协助用户解决故障及问题，但不承担由此导致的用户业务不可用责任。</p>
      <p className='p-1 leading-6'>3) 对于超出承诺范围的服务，或保修期外的服务，昊源诺信会基于双方友好合作基础，提供成本可控的有偿服务，具体可咨询昊源诺信技术服务中心。</p>
      <p className='p-1 leading-6'>4) 以下提及的情形，不在保修服务范围内：</p>
    </section>

    <section className='px-4 pb-4 pl-6 text-xs text-gray-600 leading-6'>
      <p className='p-1'>• 因采用非昊源诺信所提供设备及备件，并由其本身质量问题或不兼容性导致的故障或损坏</p>
      <p className='p-1'>• 说明文件中未提及的、未被认可的不规范操作（如带电插拔数据线等）造成的故障或损坏</p>
      <p className='p-1'>• 在非产品所规定工作环境下使用造成的故障及损坏（包括但不限于：温度过高、过低，过于潮湿或干燥，海拔过高，电压或者电流不稳定、零地电压过大，静电干扰，非正常的物理压力及振动，环境灰尘过多等）</p>
      <p className='p-1'>• 因维护不当导致的计算病毒感染、恶意软件、非法入侵等带来的故障和损坏</p>
      <p className='p-1'>• 因非昊源诺信授权的机构、人员实施的安装、维修、更改或拆卸所造成的故障和损坏</p>
      <p className='p-1'>• 因直接的或间接的不可避免的原因或不可抗拒力（如自然灾害、战争等）造成的故障或损坏</p>
      <p className='p-1'>• 因事故、滥用（包括长期超负荷运行）、误用造成的故障及损坏</p>
    </section>

    <section className='p-4'>
      <p className='p-1 font-bold'>4.保修免责条款</p>
      <p className='p-1 leading-6'>用户应对其数据的安全性自行负责。在维修前自行对系统数据、程序等进行备份，昊源诺信不对维修过程中数据、计算机程序的丢失或者损坏承担责任。</p>
      <p className='p-1 leading-6'>现场服务可能要求昊源诺信售后工程师接触非昊源诺信交付或者出品的软件或硬件。若由昊源诺信对软件或硬件进行操作，可能导致被操作的厂家的产品保修失效。用户应该保证昊源诺信的上述服务行为不会影响这种保修，或者能够接受由此带来的失效结果。昊源诺信不对第三方保修失效所造成的影响承担任何责任。</p>
      <p className='p-1 leading-6'>在服务器产品良好运行时，昊源诺信对用户的责任仅限于按照相应的条款进行产品的维修和更换，除此之外，无其他担保。除非国家法律有其他明确规定，昊源诺信将遵照法律执行。</p>
    </section>

    <section className='p-4'>
      <p className='p-1 font-bold'>5.协议解释与说明</p>
      <p className='p-1 leading-6'>本售后服务协议适用于用户在中华人民共和国境内（港、澳、台地区除外）销售、购买并使用的由昊源诺信提供的服务器产品。</p>
    </section>
  </div>
}
