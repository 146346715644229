import { Image } from 'components'

export default function View ({ res, nav, store }) {
  if(store.responsive.isDesktop) {
    return <div className='bg-white'>
      <Image className='w-full h-auto object-cover' src={store.config.loadImg('/resources/banner-6.jpg')} />

      <h1 className='pt-6 text-gray-800 text-2xl text-center font-bold'>
        合作伙伴
      </h1>

      <ul className='p-24 px-32 grid grid-cols-4 gap-x-16 gap-y-24 widescreen:gap-x-24 widescreen:gap-y-32 widescreen:px-64'>
        {R.times(R.inc, 16).map(elm => {
          return <li>
            <Image key={elm} className='w-full h-24 object-contain' src={store.config.loadImg(`/resources/partnership-logo-${elm}.png`)} />
          </li>
        })}
      </ul>
    </div>
  }

  return <div className='bg-white'>
    <div>{nav}</div>

    <Image className='w-full h-auto object-cover' src={store.config.loadImg('/resources/banner-6.jpg')} />

    <h1 className='pt-6 text-gray-800 text-lg text-center font-bold'>
      合作伙伴
    </h1>

    <ul className='p-6 grid grid-cols-2 gap-x-8 gap-y-16'>
      {R.times(R.inc, 16).map(elm => {
        return <li>
          <Image key={elm} className='w-full h-12 object-contain' src={store.config.loadImg(`/resources/partnership-logo-${elm}.png`)} />
        </li>
      })}
    </ul>
  </div>
}
