import { useRequest } from 'ahooks'

export default function View ({ route, store, external }) {
  const res = useRequest(async () => {
    const result = await external.api.get('/news/selectById', {
      params: {
        id: route.params.id,
      }
    })

    return result.data
  }, {
    staleTime: -1,
    cacheKey: `consult:detail:${route.params.id}`,
  })

  React.useEffect(() => {

    scrollTo(0, 0)

  }, [])

  if(store.responsive.isDesktop) {
    return res.loading ? <main className='animate-pulse py-64 text-xl text-center'>Loading data...</main> : <main className='bg-white px-64 min-h-screen'>
      <h1 className='p-8 text-2xl text-center font-bold'>{res.data?.title}</h1>
      <time className='p-4 text-lg text-gray-600'>发布时间: {moment(res.data?.releaseTime).format('YYYY-MM-DD')}</time>

      <section className='p-4 ql-editor text-base'>
        <div dangerouslySetInnerHTML={{ __html: res.data?.content }} />
      </section>
    </main>
  }

  if(res.loading) {
    return <div className='py-8 text-center' style={{ minHeight: '80vh' }}>加载中...</div>
  }

  return <main className='bg-white' style={{ minHeight: '80vh' }}>
    <h1 className='p-6 text-lg text-center font-bold'>{res.data?.title}</h1>
    <time className='p-4 text-gray-600'>发布时间: {moment(res.data?.releaseTime).format('YYYY-MM-DD')}</time>

    <section className='ql-editor p-4'>
      <div dangerouslySetInnerHTML={{ __html: res.data?.content }} />
    </section>
  </main>
}
