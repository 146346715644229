import { DotLoading } from 'antd-mobile'

export default function View ({ loading = false, className = '', children, ...rest }) {
  return <button
    {...rest}
    disabled={loading}
    className={`p-6 py-1.5 transition duration-300 select-none bg-blue-800 text-white rounded-full outline-none ring-blue-800 ring-opacity-50 focus:ring disabled:opacity-50 active:opacity-80 ${className}`.trim()}
  >
    {loading ? <DotLoading color='white' /> : children}
  </button>
}
