import { Header, Footer } from 'components'

export default (Target: React.FC<any>) => function Layout(props) {
  return <div>
    <div>
      <Header {...props} />
    </div>

    <div className='mt-14 desktop:mt-0'>
      <Target {...props} />
    </div>

    <div>
      <Footer {...props} />
    </div>
  </div>
}
