import { useRef } from 'react'
import { useReactive, useRequest } from 'ahooks'

import { Button } from 'components'
import { CenterPopup } from 'antd-mobile'
import { APILoader, Map, InfoWindow } from '@uiw/react-amap'

export default function View ({ res, nav, store }) {
  const data = useReactive<any>({ visible: false })
  const request = useRequest<any, any>(e => store.external.api.post('/contact/save', e), { manual: true })

  const submit = R.pipe(R.tap<any>(e => e.preventDefault()), async () => {
    await request.runAsync(data)

    Object.assign(data, {
      visible: true,
    })

    const envs = [
      'name',
      'desc',
      'email',
      'phone',
      'company',
    ]

    envs.forEach(elm => {
      Object.assign(data, { [ elm ]: '' })
    })
  })

  if(store.responsive.isDesktop) {
    return <div className='bg-white'>
      <img className='w-full h-auto object-cover' src={store.config.loadImg('/resources/banner-6.jpg')} />

      <section className='p-24 flex items-center justify-center space-x-16'>
        <APILoader akay='ba97f34980e4dc64b68048e093514747'>
          <div className='flex-none' style={{ width: 800, height: 500 }}>
            <Map zoom={16} center={res.address?.position}>
              <InfoWindow
                visiable
                isCustom
                offset={[0, -24]}
                position={res.address?.position}
              >
                <div className='p-4 w-64 rounded-sm text-center text-sm bg-white relative shadow flex flex-col items-center justify-center space-y-2'>
                  <div className='text-blue-900 font-bold text-base'>{res.address?.desc.split('-')[0]}</div>
                  <div className='text-gray-800'>{res.address?.desc.split('-')[1]}</div>
                  <div className='w-0 h-0 absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full' style={{ border: '8px solid transparent', borderTop: '16px solid white' }} />
                </div>
              </InfoWindow>
            </Map>
          </div>
        </APILoader>

        <div className='space-y-4'>
          <h1 className='text-gray-800 text-2xl font-bold'>
            联系我们
          </h1>

          <button className='px-3 py-1 border border-gray-600 outline-none text-base'>深圳总部</button>

          <ul className='text-gray-600 space-y-2 leading-loose whitespace-pre-wrap text-base'>
            <li>服务热线：4006-997-916</li>
            <li>电话：0755-86936235  传真：0755-86936236</li>
            <li>邮箱：marketing@hynx.com.cn</li>
            <li>QQ：427959771</li>
            <li>深圳总部：{res.address?.desc.split('-')[1]}</li>
            <li>广州公司：广东省广州市天河区大观中路95号1栋</li>
            <li>武汉公司：湖北省武汉市东湖新技术开发区</li>
            <li>成都公司：四川省成都市高新区吉泰路666号福年广场T2</li>
          </ul>
        </div>
      </section>

      <section className='p-4 px-64 pb-16 space-y-6 text-base'>
        <h1 className='p-2 text-gray-500 text-2xl text-center'>在线留言</h1>

        <div className='flex justify-center items-center space-x-3'>
          <Button className='px-0 py-1.5 w-40 text-center'>产品购买及资讯</Button>
          <Button className='px-0 py-1.5 w-40 text-center'>加入合作计划</Button>
        </div>

        <p className='p-2 text-gray-600'>请填写详细信息(带*号的为必填项)</p>

        <form className='space-y-4' onSubmit={submit}>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='flex items-stretch'>
              <label
                children='*姓名'
                className='flex-none w-24 border border-r-0 border-gray-600 flex items-center justify-center'
              />

              <input
                value={data.name}
                onChange={e => Object.assign(data, { name: e.target.value })}
                required
                name='name'
                className='flex-auto p-2 transition duration-300 outline-none border border-gray-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
              />
            </div>

            <div className='flex items-stretch'>
              <label
                children='*手机'
                className='flex-none w-24 border border-r-0 border-gray-600 flex items-center justify-center'
              />

              <input
                value={data.phone}
                onChange={e => Object.assign(data, { phone: e.target.value })}
                required
                type='number'
                name='phone'
                className='flex-auto p-2 transition duration-300 outline-none border border-gray-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='flex items-stretch'>
              <label
                children='*公司名称'
                className='flex-none w-24 border border-r-0 border-gray-600 flex items-center justify-center'
              />

              <input
                value={data.company}
                onChange={e => Object.assign(data, { company: e.target.value })}
                required
                name='company'
                className='flex-auto p-2 transition duration-300 outline-none border border-gray-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
              />
            </div>

            <div className='flex items-stretch'>
              <label
                children='*邮箱'
                className='flex-none w-24 border border-r-0 border-gray-600 flex items-center justify-center'
              />

              <input
                value={data.email}
                onChange={e => Object.assign(data, { email: e.target.value })}
                required
                type='text'
                name='email'
                className='flex-auto p-2 transition duration-300 outline-none border border-gray-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
              />
            </div>
          </div>

          <div className='flex'>
            <textarea
              value={data.desc}
              onChange={e => Object.assign(data, { desc: e.target.value })}
              required
              rows={8}
              name='desc'
              placeholder='*您的留言'
              className='flex-auto p-2 transition duration-300 outline-none border border-gray-600 placeholder-gray-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
            />
          </div>

          <div className='flex justify-end pr-4'>
            <Button type='submit' loading={request.loading}>
              提交
            </Button>
          </div>
        </form>
      </section>

      <CenterPopup visible={data.visible} bodyClassName='p-4 space-y-4'>
        <section className='space-y-2'>
          <p className='text-center text-gray-800 text-sm leading-6 font-bold'>温馨提示</p>
          <p className='text-center text-gray-700 text-sm leading-6'>您的留言提交成功, 客服稍后将会联系您, 请耐心等待.</p>
        </section>

        <section className='flex items-center justify-center space-x-4'>
          <Button onClick={() => Object.assign(data, { visible: false })}>知道了</Button>
        </section>
      </CenterPopup>
    </div>
  }

  return <div className='bg-white'>
    <div>{nav}</div>

    <img className='w-full h-auto object-cover' src={store.config.loadImg('/resources/banner-6.jpg')} />

    <h1 className='pt-6 text-gray-800 text-lg text-center font-bold'>
      联系我们
    </h1>

    <APILoader akay='ba97f34980e4dc64b68048e093514747'>
      <div className='p-4 w-full h-64'>
        <Map zoom={16} center={res.address?.position}>
          <InfoWindow
            visiable
            isCustom
            offset={[0, -24]}
            position={res.address?.position}
          >
            <div className='p-2 w-48 rounded-sm text-center bg-white relative shadow flex flex-col items-center justify-center space-y-1'>
              <div className='text-blue-900 font-bold text-sm'>{res.address?.desc.split('-')[0]}</div>
              <div className='text-gray-800 text-xs'>{res.address?.desc.split('-')[1]}</div>
              <div className='w-0 h-0 absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full' style={{ border: '8px solid transparent', borderTop: '16px solid white' }} />
            </div>
          </InfoWindow>
        </Map>
      </div>
    </APILoader>

    <section className='p-4 space-y-4'>
      <button className='p-1 px-4 border border-gray-600 outline-none'>深圳总部</button>

      <ul className='text-gray-600 space-y-2 leading-6 whitespace-pre-wrap'>
        <li>服务热线：4006-997-916</li>
        <li>电话：0755-86936235  传真：0755-86936236</li>
        <li>邮箱：marketing@hynx.com.cn</li>
        <li>QQ：427959771</li>
        <li>深圳总部：{res.address?.desc.split('-')[1]}</li>
        <li>广州公司：广东省广州市天河区大观中路95号1栋</li>
        <li>武汉公司：湖北省武汉市东湖新技术开发区</li>
        <li>成都公司：四川省成都市高新区吉泰路666号福年广场T2</li>
      </ul>
    </section>

    <section className='p-4 space-y-2'>
      <h1 className='p-2 text-gray-500 text-lg text-center'>在线留言</h1>

      <div className='flex justify-center items-center space-x-3'>
        <Button className='px-0 py-1.5 w-32 text-center'>产品购买及资讯</Button>
        <Button className='px-0 py-1.5 w-32 text-center'>加入合作计划</Button>
      </div>

      <p className='p-2 text-gray-600'>请填写详细信息(带*号的为必填项)</p>

      <form className='space-y-4' onSubmit={submit}>
        <div className='flex items-stretch'>
          <label
            children='*姓名'
            className='flex-none w-24 border border-r-0 border-gray-600 flex items-center justify-center'
          />

          <input
            value={data.name}
            onChange={e => Object.assign(data, { name: e.target.value })}
            required
            name='name'
            className='flex-auto p-2 transition duration-300 outline-none border border-gray-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
          />
        </div>

        <div className='flex items-stretch'>
          <label
            children='*公司名称'
            className='flex-none w-24 border border-r-0 border-gray-600 flex items-center justify-center'
          />

          <input
            value={data.company}
            onChange={e => Object.assign(data, { company: e.target.value })}
            required
            name='company'
            className='flex-auto p-2 transition duration-300 outline-none border border-gray-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
          />
        </div>

        <div className='flex items-stretch'>
          <label
            children='*手机'
            className='flex-none w-24 border border-r-0 border-gray-600 flex items-center justify-center'
          />

          <input
            value={data.phone}
            onChange={e => Object.assign(data, { phone: e.target.value })}
            required
            type='number'
            name='phone'
            className='flex-auto p-2 transition duration-300 outline-none border border-gray-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
          />
        </div>

        <div className='flex items-stretch'>
          <label
            children='*邮箱'
            className='flex-none w-24 border border-r-0 border-gray-600 flex items-center justify-center'
          />

          <input
            value={data.email}
            onChange={e => Object.assign(data, { email: e.target.value })}
            required
            type='text'
            name='email'
            className='flex-auto p-2 transition duration-300 outline-none border border-gray-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
          />
        </div>

        <div className='flex'>
          <textarea
            value={data.desc}
            onChange={e => Object.assign(data, { desc: e.target.value })}
            required
            rows={8}
            name='desc'
            placeholder='*您的留言'
            className='flex-auto p-2 transition duration-300 outline-none border border-gray-600 placeholder-gray-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
          />
        </div>

        <div className='flex justify-end pr-4'>
          <Button type='submit' loading={request.loading}>
            提交
          </Button>
        </div>
      </form>
    </section>

    <CenterPopup visible={data.visible} bodyClassName='p-4 space-y-4'>
      <section className='space-y-2'>
        <p className='text-center text-gray-800 text-sm leading-6 font-bold'>温馨提示</p>
        <p className='text-center text-gray-700 text-sm leading-6'>您的留言提交成功, 客服稍后将会联系您, 请耐心等待.</p>
      </section>

      <section className='flex items-center justify-center space-x-4'>
        <Button onClick={() => Object.assign(data, { visible: false })}>知道了</Button>
      </section>
    </CenterPopup>
  </div>
}
