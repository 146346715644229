import { useEffect } from 'react'
import { useRequest } from 'ahooks'

import { Image, Spin } from 'components'
import { Empty, SearchBar } from 'antd-mobile'

export default function View ({ res, route, store, external }) {
  const request = useRequest<any, any>(async e => {
    const result = await external.api.get('/product/select', {
      params: {
        name: e,
        type: route.params.id,
      }
    })

    return result.data.list
  }, {
    loadingDelay: 600,
    refreshDeps: [ route.params.id ],
  })

  useEffect(() => {

    scrollTo(0, 0)

  }, [])

  if(store.responsive.isDesktop) {
    return <main>
      <Image className='w-full object-cover' src={store.config.loadImg('/resources/banner-1.jpg')} />

      <ul className='bg-white flex flex-wrap items-center justify-center text-lg'>
        {res.product.map(elm => {
          return <li
            key={elm.id}
            onClick={() => store.navigate.absolute(`/product/list/${elm.id}`)}
            className={`px-8 py-4 cursor-pointer hover:text-blue-800 ${route.params.id == elm.id ? 'text-blue-800' : ''}`}
          >
            {elm.title}
          </li>
        })}
      </ul>

      {request.loading ? <div className='animate-pulse py-64 text-xl text-center'>Loading data...</div> : R.propOr(0, 'length', request.data) == 0 ? <div className='py-64 text-xl text-center'>暂无相关产品.</div> : <ul className='p-8 px-32 grid grid-cols-3 gap-8 widescreen:px-64'>
        {request.data?.map(elm => {
          return <li
            key={elm.id}
            onClick={() => store.navigate.go(`/product/detail/${elm.id}`)}
            className='p-6 px-8 bg-white cursor-pointer'
          >
            <div>
              <Image className='w-full h-80 object-contain' src={elm.url} />
            </div>

            <div className='divide-y leading-6'>
              <p className='py-4 text-xl text-gray-800'>{elm.title}</p>
              <p className='py-4 text-base text-gray-600'>{elm.desc}</p>
            </div>
          </li>
        })}
      </ul>}
    </main>
  }

  return <main>
    <ul className='p-4 bg-white flex flex-wrap items-center divide-x'>
      {res.product.map(elm => {
        const classname = external.declare('px-2 my-2', {
          'font-bold': route.params.id == elm.id,
          'text-blue-800': route.params.id == elm.id,
        })

        return <li
          key={elm.id}
          className={classname}
          onClick={() => store.navigate.absolute(`/product/list/${elm.id}`)}
        >
          {elm.title}
        </li>
      })}
    </ul>

    <Image className='w-full object-cover' src={store.config.loadImg('/resources/banner-1.jpg')} />

    <div className='m-4'>
      <SearchBar
        placeholder='输入产品名搜索'
        onClear={() => request.run()}
        onSearch={value => request.run(value)}
      />
    </div>

    <Spin className='m-4 space-y-4' spinning={request.loading} style={{ minHeight: 250 }}>
      {R.propOr(0, 'length', request.data) == 0 ? <Empty style={{ height: 250, background: '#fff' }} description='暂无产品信息' /> : request.data.map(elm => {
        return <div
          key={elm.id}
          onClick={() => store.navigate.go(`/product/detail/${elm.id}`)}
          className='p-4 flex flex-col items-center justify-center bg-white rounded space-y-2 shadow'
        >
          <Image className='p-4 h-40 object-contain' src={elm.url} />
          <h1 className='text-xl text-gray-800'>{elm.title}</h1>
          <h3 className='text-sm text-gray-400'>了解详情</h3>
        </div>
      })}
    </Spin>
  </main>
}
