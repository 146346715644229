import { useScroll } from 'ahooks'

export default function View ({ store }) {
  const data = useScroll(document)

  function top(value) {
    scrollTo(0, value)

    if(value > 0) {
      requestAnimationFrame(() => top(value - 100 < 0 ? 0 : value - 100))
    }
  }

  if(store.responsive.isDesktop == false || data?.top <= 600) {
    return null
  }

  return <div
    onClick={() => top(data.top)}
    title='回到顶部'
    className='p-2 px-3 rounded-lg fixed right-8 bottom-24 bg-black bg-opacity-40 z-50 cursor-pointer hover:bg-opacity-60 transition duration-300'
  >
    <div className='text-3xl fa fa-arrow-up text-white' />
  </div>
}
