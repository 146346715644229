import Contact from './contact'
import Profile from './profile'
import Partnership from './partnership'

export default function View ({ res, store, route }) {
  React.useEffect(() => {

    scrollTo(0, 0)

  }, [])

  const list = [
    {
      url: '/about/profile',
      name: '企业简介',
    },
    {
      url: '/about/partnership',
      name: '合作伙伴',
    },
    {
      url: '/about/contact',
      name: '联系我们',
    },
  ]

  const nav = <nav>
    <ul className='p-4 bg-white flex flex-wrap items-center divide-x'>
      {list.map(elm => (
        <li
          key={elm.url}
          children={elm.name}
          onClick={() => store.navigate.go(elm.url)}
          className={route.url == elm.url ? 'px-2 my-2 font-bold text-blue-800' : 'px-2 my-2'}
        />
      ))}
    </ul>
  </nav>

  const getView = R.cond([
    [R.equals('partnership'), () => <Partnership store={store} nav={nav} res={res} />],
    [R.equals('profile'), () => <Profile store={store} nav={nav} res={res} />],
    [R.equals('contact'), () => <Contact store={store} nav={nav} res={res} />],
  ])

  return <main>
    {getView(route.params.id)}
  </main>
}
