export default [
  {
    key: '/home',
    title: '首页',
  },
  {
    key: '/product',
    title: '产品中心',
  },
  {
    key: '/program',
    title: '方案中心',
    children: [
      {
        title: '深度学习和AI解决方案',
        key: '/program/1',
      },
      {
        title: '硬件加速器集群',
        key: '/program/6',
      },
      {
        title: '美超微 Hadoop',
        key: '/program/2',
      },
      {
        title: '高性能计算（HPC）解决方案',
        key: '/program/3',
      },
      // {
      //   title: '软件定义存储和内存',
      //   key: '/program/4',
      // },
      {
        title: 'Ceph存储解决方案',
        key: '/program/5',
      },
    ]
  },
  {
    key: '/consult',
    title: '资讯中心',
    children: [
      {
        title: '公司资讯',
        key: '/consult/1',
      },
      {
        title: '行业资讯',
        key: '/consult/2',
      },
    ]
  },
  {
    key: '/server',
    title: '服务中心',
    children: [
      {
        title: '服务中心',
        key: '/server/home',
      },
      {
        title: '售后服务',
        key: '/server/sla',
      },
      {
        title: '保修服务',
        key: '/server/warranty',
      },
      {
        title: '资料下载',
        key: '/server/download',
      },
    ]
  },
  {
    key: '/about',
    title: '关于昊源',
    children: [
      {
        title: '企业简介',
        key: '/about/profile',
      },
      {
        title: '合作伙伴',
        key: '/about/partnership',
      },
      {
        title: '联系我们',
        key: '/about/contact',
      },
    ]
  },
]
