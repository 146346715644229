import Home from './home'
import Sales from './sales'
import Warranty from './warranty'
import Download from './download'

export default function View ({ store, route }) {
  React.useEffect(() => {

    scrollTo(0, 0)

  }, [])

  const list = [
    {
      url: '/server/home',
      name: '服务中心',
    },
    {
      url: '/server/sla',
      name: '售后服务',
    },
    {
      url: '/server/warranty',
      name: '保修服务',
    },
    {
      url: '/server/download',
      name: '资料下载',
    },
  ]

  const getView = R.cond([
    [R.equals('download'), () => <Download store={store} />],
    [R.equals('warranty'), () => <Warranty store={store} />],
    [R.equals('sla'), () => <Sales store={store} />],
    [R.equals('home'), () => <Home store={store} />],
  ])

  return <main>
    <nav className='desktop:hidden'>
      <ul className='p-4 bg-white flex flex-wrap items-center divide-x'>
        {list.map(elm => (
          <li
            key={elm.url}
            children={elm.name}
            onClick={() => store.navigate.go(elm.url)}
            className={route.url == elm.url ? 'px-2 my-2 font-bold text-blue-800' : 'px-2 my-2'}
          />
        ))}
      </ul>
    </nav>

    <section>
      <img className='w-full h-auto object-cover' src={store.config.loadImg('/resources/banner-4.jpg')} />
    </section>

    <nav className='hidden desktop:block'>
      <ul className='bg-white flex items-center justify-center text-lg'>
        {list.map(elm => (
          <li
            key={elm.url}
            children={elm.name}
            onClick={() => store.navigate.go(elm.url)}
            className={route.url == elm.url ? 'px-8 py-4 cursor-pointer hover:text-blue-800 text-blue-800' : 'px-8 py-4 cursor-pointer hover:text-blue-800'}
          />
        ))}
      </ul>
    </nav>

    <section>
      {getView(route.params.id)}
    </section>
  </main>
}
