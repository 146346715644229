import { useRequest } from 'ahooks'

import { QRCodeCanvas } from 'qrcode.react'
import { Image, Spin, Button } from 'components'
import { ImageViewer, Popover } from 'antd-mobile'
import { Swiper, SwiperSlide } from 'swiper/react'

export default function View ({ store, route, external }) {
  const res = useRequest<any, any>(async () => {
    const result = await external.api.get('/product/selectById', {
      params: {
        id: route.params.id,
      }
    })

    return result.data
  })

  React.useEffect(() => {

    scrollTo(0, 0)

  }, [])

  if(store.responsive.isDesktop) {
    return <main>
      <Image className='w-full object-cover' src={store.config.loadImg('/resources/banner-7.jpg')} />

      {res.loading ? <div className='animate-pulse py-64 text-xl text-center'>Loading data...</div> : <div className='px-32 widescreen:px-64'>
        <section className='mx-auto my-16 p-8 bg-white flex items-center space-x-8'>
          <div className='space-y-4 w-1/2'>
            <div className='p-2 flex justify-center'>
              <img className='w-full object-cover' src={res.data?.productImages[0].url} />
            </div>

            <Swiper slidesPerView={5} spaceBetween={30} className='select-none p-2'>
              {res.data?.productImages.map((elm, index) => {
                return <SwiperSlide key={elm.id}>
                  <div
                    className='cursor-pointer'
                    onClick={() => ImageViewer.Multi.show({ defaultIndex: index, images: res.data.productImages.map(R.prop('url')) })}
                  >
                    <Image className='w-full h-full object-cover' src={elm.url} />
                  </div>
                </SwiperSlide>
              })}
            </Swiper>
          </div>

          <div className='space-y-8'>
            <div className='space-y-4'>
              <div className='text-3xl font-bold'>{res.data?.title}</div>
              <div className='text-base text-gray-900 leading-7 whitespace-pre-wrap'>{res.data?.content}</div>
            </div>

            <div className='space-y-4'>
              <div className='text-lg text-gray-600 font-bold'>应用场景</div>
              <div className='text-base text-gray-900 leading-6 whitespace-pre-wrap'>{res.data?.scene}</div>
            </div>

            <div className='flex items-center space-x-4'>
              <Button className='text-base' onClick={() => store.navigate.go(`/server/download?query=${res.data?.title}`)}>资料下载</Button>

              <Button className='text-base' onClick={() => document.getElementById('nb_icon_wrap').click?.()}>在线咨询</Button>

              <Popover
                trigger='click'
                content={<div className='m-1 p-2 border-4 border-gray-800'><QRCodeCanvas value={location.href} /></div>}
              >
                <Button className='text-base'>二维码分享</Button>
              </Popover>
            </div>
          </div>
        </section>

        <table className='my-16 w-full border-collapse table-auto'>
          <thead>
            <tr>
              <th colSpan={2} className='p-4 w-64 border border-gray-600 bg-blue-800 text-white text-2xl font-bold'>技术规格</th>
            </tr>
          </thead>

          <tbody>
            {res.data?.specification.map(elm => {
              return <tr
                key={elm.id}
                className='odd:bg-white bg-blue-100'
              >
                <td className='p-4 py-2 text-base text-center text-gray-800 border border-gray-600 whitespace-pre-wrap'>{elm.name}</td>
                <td className='p-4 py-2 text-base text-left text-gray-800 border border-gray-600 whitespace-pre-wrap'>{elm.value}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>}
    </main>
  }

  return <main>
    <Image className='w-full object-cover' src={store.config.loadImg('/resources/banner-1.jpg')} />

    <Spin className='p-4 min-h-screen' spinning={res.loading}>
      <div className='bg-white p-4 space-y-4'>
        <h1 className='text-xl text-center'>{res.data?.title}</h1>

        <div className='w-4/5 mx-auto'>
          <Image className='w-full object-cover' src={res.data?.productImages[0].url} onClick={e => ImageViewer.Multi.show({ images: res.data.productImages.map(R.prop('url')) })} />
        </div>

        <ul className='flex items-stretch justify-center'>
          {res.data?.productImages.map((elm, index) => {
            return <li key={elm.id} className='m-2 p-2 flex-1 flex items-center rounded shadow-lg' onClick={e => ImageViewer.Multi.show({ defaultIndex: index, images: res.data.productImages.map(R.prop('url')) })}>
              <Image className='w-full object-cover' src={elm.url} />
            </li>
          })}
        </ul>

        <pre className='whitespace-pre-wrap'>{res.data?.content}</pre>

        <section>
          <h3 className='text-base text-gray-600 font-bold'>应用场景</h3>
          <p>{res.data?.scene}</p>
        </section>

        <section className='flex items-center justify-center space-x-4'>
          <Button className='px-4' onClick={() => store.navigate.go(`/server/download?query=${res.data?.title}`)}>资料下载</Button>

          <Button className='px-4' onClick={() => document.getElementById('nb_icon_wrap').click?.()}>在线咨询</Button>

          <Popover
            trigger='click'
            content={<div className='m-1 p-2 border-4 border-gray-800'><QRCodeCanvas value={location.href} /></div>}
          >
            <Button className='px-4'>二维码分享</Button>
          </Popover>
        </section>
      </div>

      <table className='my-4 border-collapse'>
        <thead>
          <tr className='bg-blue-800 text-white'>
            <th colSpan={2} className='p-2 border border-gray-600'>技术规格</th>
          </tr>
        </thead>

        <tbody>
          {res.data?.specification.map(elm => {
            return <tr key={elm.id} className='odd:bg-white bg-blue-100'>
              <td className='p-2 text-xs text-center text-gray-600 border border-gray-600 w-20 whitespace-pre-wrap'>{elm.name}</td>
              <td className='p-2 text-xs text-left text-gray-600 border border-gray-600 w-auto whitespace-pre-wrap'>{elm.value}</td>
            </tr>
          })}
        </tbody>
      </table>

      <div className='p-4 flex justify-center'>
        <Button onClick={e => scrollTo(0, 0)}>回到顶部</Button>
      </div>
    </Spin>
  </main>
}
